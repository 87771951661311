* {
    box-sizing: border-box;
    vertical-align: top;
    /* outline: 0.3px dotted rgba(0,0,0,0.15); */
}

body {
    margin: 0;
    background-color: whitesmoke;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* p {
    margin: 0px;
} */

a {
    color: #0029e2;
    text-decoration: none;
} 

a:hover {
    color: #c00151;
}

input {
    width: 60%;
    min-width: 120px;
    padding: 4px;
    margin-bottom: 16px;
}

button {
    padding: 4px 12px;
}

h1 {
    font-size: 28px;
    margin: 0 0 32px 0;
}

.add-margin {
    margin: 0 16px 16px 0;
}

.meme-form {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 32px;
    width: 100%;
    max-width: 320px;
}

.meme {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    max-width: 320px;
}

.meme > img {
    width: 100%;
}

.meme > h2 {
    position: absolute;
    width: 80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 15px 0;
    padding: 0 5px;
    font-family: impact, sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}

.meme > .bottom {
    bottom: 0;
}

.meme > .top {
    top: 0;
}